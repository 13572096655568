html, body, header, #intro {
  height: 100%;
}

#intro {
  background: url("3E.7c181c39.jpg") center / cover no-repeat fixed;
}

.top-nav-collapse {
  background-color: #f80;
}

@media (width <= 768px) {
  .navbar:not(.top-nav-collapse) {
    background-color: #f80;
  }
}

@media (width >= 800px) and (width <= 850px) {
  .navbar:not(.top-nav-collapse) {
    background-color: #f80;
  }
}

html {
  scroll-behavior: smooth;
}

video {
  object-fit: cover;
}

#student .card {
  min-width: 30%;
}

#student h4 {
  justify-content: space-between;
  display: flex;
}

#class_schedule .table td {
  vertical-align: middle;
}

#class_schedule p {
  margin-bottom: 0;
}
/*# sourceMappingURL=index.4e13bb68.css.map */
