html,
body,
header,
#intro {
    height: 100%;
}

#intro {
    background:url("./img/3E.jpg") no-repeat center center fixed;
    background-size: cover;
}

.top-nav-collapse {
    background-color: #FF8800;
}
@media (max-width: 768px) {
    .navbar:not(.top-nav-collapse) {
        background-color: #FF8800;
    }
}
@media (min-width: 800px) and (max-width: 850px) {
    .navbar:not(.top-nav-collapse) {
        background-color: #FF8800;
    }
}
html {
    scroll-behavior: smooth;
}

video {
    object-fit: cover
}

#student .card {
    min-width: 30%;

}

#student h4 {
    display: flex;
    justify-content: space-between;
}

#class_schedule .table td {
    vertical-align: middle;
}

#class_schedule p {
    margin-bottom: 0;
}
